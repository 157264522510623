<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="type" label="报名方式" :formatter="typeFormat" show-overflow-tooltip></el-table-column>
      <el-table-column prop="status" label="状态" :formatter="statusFormat" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fee" label="金额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pay_time" label="完成时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pay_type" label="支付类型" :formatter="payTypeFormat" show-overflow-tooltip></el-table-column>
      <el-table-column prop="level" label="报名时等级" show-overflow-tooltip>
        <template #default="s">
          {{ userLevel2Tag(s.row.level) }}
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>
import {levelOption} from "@/common/mod/user";

export default {
  name: "course",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      levelOption: levelOption,
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    scope_option() {
      return this.levelOption;
    }
  },
  methods: {
    typeFormat(item) {
      let str = "";
      switch (parseInt(item.type)) {
        case 1:
          str = "免费";
          break;
        case 2:
          str = "付费";
          break;
      }
      return str;
    },
    statusFormat(item) {
      let str = "";
      switch (parseInt(item.status)) {
        case 1:
          str = "未完成";
          break;
        case 2:
          str = "已完成";
          break;
      }
      return str;
    },
    payTypeFormat(item) {
      let str = "";
      switch (parseInt(item.pay_type)) {
        case 1:
          str = "微信公众号h5支付";
          break;
        case 2:
          str = "app微信支付";
          break;
        case 3:
          str = "app支付宝支付";
          break;
        case 4:
          str = "微信小程序支付";
          break;
      }
      return str;
    },
    userLevel2Tag(level) {
      let tagObj = this.scope_option.filter(item => item.level === parseInt(level));
      if (tagObj.length === 0) {
        return "异常"
      }
      return (tagObj.pop()).title
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.shop.plugin.knowledge.courseApplyList({
        id: parseInt(this.$route.query.id),
        page: this.page
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>